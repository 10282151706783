<template>
  <div class="content">
    <div class="pagination">
      <div class="item" @click="prev"></div>
      <div class="item" @click="next"></div>
      <div class="item" @click="backTo">返回</div>
    </div>
    <div class="codes">
      <div class="code" v-for="code in codes" :key="code.id">
        <div class="icon" v-html="code.icon"></div>
        <span class="name">{{ code.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode'
import { CODES } from '@/constants'

export default {
  name: 'Qrcode',
  data() {
    return {
      codes: [],
      isFirst: true,
      isLast: false,
      currPage: 1,
    }
  },
  mounted() {
    this.setCodes()
  },
  methods: {
    async setCodes() {
      // console.time('codes')
      const { id } = this.$route.query
      const codesP = CODES[id].map(async (code) => ({
        name: code.name,
        icon: await QRCode.toString(code.url),
      }))
      this.codes = await Promise.all(codesP)
      // console.timeEnd('codes')
    },
    prev() {
      // this.codes = this.codes.slice()
    },
    next() {
      // this.codes = this.codes.slice()
    },
    backTo() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import './content.scss';
</style>
